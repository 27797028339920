import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className="container">
        <div className={Styled.wrapper}>
          <StaticQuery
            query={graphql`
              {
                allFile(
                  filter: { sourceInstanceName: { eq: "industries-pages" } }
                ) {
                  nodes {
                    childMarkdownRemark {
                      frontmatter {
                        slug
                        path
                        title
                        subtitle
                        content
                      }
                    }
                  }
                }
              }
            `}
            render={data =>
              data.allFile.nodes.map(({ childMarkdownRemark }, i) => (
                <div key={i} className={Styled.cardWrapper}>
                  <div
                    className={`${Styled.card} ${
                      i % 2 ? Styled.reverse : null
                    }`}
                  >
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: childMarkdownRemark.frontmatter.title,
                      }}
                      className="lg-text bold"
                    ></h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: childMarkdownRemark.frontmatter.subtitle,
                      }}
                    ></div>
                    <Link
                      to={childMarkdownRemark.frontmatter.slug}
                      className="explore sm-text bold"
                    >
                      Learn more
                    </Link>
                  </div>
                  <img
                    alt="Accountancy & Finance"
                    src={require(`../../../assets/img/industries/${childMarkdownRemark.frontmatter.path}.png`)}
                  ></img>
                </div>
              ))
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
