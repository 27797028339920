import React from "react"
import Divider from "../../../assets/img/divider.svg"

import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <div className={Styled.titleWrapper}>
            <h1 className={`${Styled.heroTitle} md-title bold`}>
              Proven digital solutions and services across major industries.
            </h1>
            <p className={`${Styled.heroSubtitle} lg-text`}>
              We deliver fast results that enable our clients to achieve high
              performance.
            </p>
          </div>
        </div>
      </div>
      <img
        alt="Hero Divider"
        width="100%"
        className={Styled.divider}
        src={Divider}
      ></img>
    </section>
  )
}

export default Hero
